.newsletter-subscription {
  @extend .pt-8;

  .container-lg {
    @extend .d-flex;
    @extend .px-0;
    @extend .position-relative;
    @extend .justify-content-center;
    @extend .mb-lg-10;

    margin-bottom: 3.5rem; // 56px
  }

  .decoration {
    @extend .position-absolute;
    @extend .d-none;
    @extend .d-md-block;

    top: -30px;
    left: -60px;
    z-index: -1;

    color: #ffc267;
  }

  .cover-image-container {
    @extend .d-none;
    @extend .d-md-block;

    // shrinks two times faster than .newsletter-content
    // which has flex-shrink: 1
    flex-shrink: 2;
    flex-grow: 2;
    margin-right: 2.5rem; // 40px

    @include media-breakpoint-up(lg) {
      margin-right: 7.25rem; // 114px
    }
  }

  .cover-image {
    @include object-fit-cover;
    @extend .position-relative;
    @extend .w-100;
    @extend .img-fluid;
    @extend .rounded-top-left;
    @extend .rounded-bottom-right;

    max-width: 500px;
    max-height: 700px;

    z-index: 2;
  }

  .newsletter-content {
    // shrinks two times slower than .cover-image-container
    // which has flex-shrink: 2
    flex-shrink: 1;
    padding: 0 1.375rem; // 0 22 px

    font-size: $font-size-sm; // 16px
    text-align: center;

    @include media-breakpoint-up(md) {
      // default size before remaining space is distributed
      flex-basis: 29.75rem; // 476px
      padding: 0;

      margin-right: 3.934rem; // 63px

      text-align: left;
      font-size: $font-size-base; // 18px
    }

    @include media-breakpoint-up(xl) {
      margin-top: 3.563rem; // 57px
    }
  }

  .heading {
    @extend .font-weight-bold;

    padding: 0 1.625rem; // 0 26px

    @include media-breakpoint-up(md) {
      padding: 0;
    }

    p {
      @extend .h2; // paragraphs from rich text
    }
  }

  .newsletter-form {
    @extend .text-left;
    @extend .position-relative;

    z-index: 2;
  }

  .content-types-form-group {
    @extend .form-group;
  }

  .content-type-control {
    @extend .custom-control;
    @extend .custom-checkbox;

    margin-bottom: 1rem; // 16px

    .custom-control-label::before {
      border-radius: 0.3rem;
    }
  }

  .btn-subscribe {
    @extend .btn-primary-default;
    @extend .btn-block;

    @include media-breakpoint-up(md) {
      height: 4.183rem; // 67 px
    }
  }

  .spinner {
    @extend .spinner-border;

    // Do not extend .d-none class as it adds "important" modificator
    // which makes hard to change dispay property via JS
    display: none;
  }

  .message-container {
    // Do not extend .d-none class as it adds "important" modificator
    // which makes hard to change dispay property via JS
    display: none;
  }

  .success_message_container {
    @extend .message-container;
  }

  .unexpected_error_container {
    @extend .message-container;
  }

  .icon-wrapper {
    @extend .text-center;

    margin-bottom: 1.688rem; // 27px
  }

  .icon {
    width: 6.25rem; // 100px
    height: 6.25rem; // 100px
  }

  .check-icon {
    @extend .icon;

    color: #69ae7f;
  }

  .alert-icon {
    @extend .icon;

    color: #fcd107;
  }

  .shape-container {
    z-index: 1;
  }

  .shape {
    @extend .shape-bottom;
  }

  .shape-img {
    padding-bottom: 2rem !important; // 32px
    color: $white;

    @include media-breakpoint-up(md) {
      padding-bottom: 8rem; // 80px
    }

    @include media-breakpoint-up(lg) {
      padding-bottom: 11.25rem; // 180px
    }
  }

  .name-form-row {
    @extend .form-row;

    .form-control {
      margin-bottom: 0.625rem; // 10px
    }
  }

  .form-control {
    &::placeholder {
      opacity: 0.65;
    }
  }
}
