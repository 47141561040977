.main-block {
  height: 100%;
}
.roku-block {
  position: relative;
  height: 27.5rem;
  background-image: url(#{$path-to-img}/roku/header.png);
  background-size: contain;
  background-position: 100%;
  background-repeat: no-repeat;
}

.roku-block-mobile {
  margin-top: 14rem;
  height: 27.5rem;
  background-image: url(#{$path-to-img}/roku/header-mobile.png);
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
}

.tv-block {
  position: relative;
  height: 37.5rem;
  background-image: url(#{$path-to-img}/roku/tv.png);
  background-size: cover;
  background-repeat: no-repeat;
  margin-top: 5rem;
}

.tv-block-mobile {
  background-size: cover;
  margin-top: -0.5rem;
  height: 24rem;
  margin-bottom: 2.5rem;

  @include media-breakpoint-down(sm) {
    height: 16rem;
  }
}

.tv-text-block-mobile {
  position: relative;
  width: 100%;
  height: 19.75rem;
  border-top-style: solid;
  border-top-color: #D18F42;
  background: #F9F9F9;
  border-radius: 0 0 0.5rem 0.5rem;
  font-family: 'DM Sans';
  font-style: normal;
  z-index: 100;

  .title {
    position: absolute;
    top: 5%;
    left: 1rem;
    width: 19.375rem;
    font-weight: 500;
    font-size: 1.25rem;
    line-height: 1.75rem;
    color: #323232;
  }

  ul {
    position: absolute;
    top: 30%;
    left: 0;
    list-style: none;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.25rem;
  }

  ul li::before {
    content: "\2022";
    color: #D18F42;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
    font-size: 1.5rem;
  }
}

.tv-text-block {
  position: absolute;
  width: 29.938rem;
  height: 19.75rem;
  left: 1rem;
  top: 0;
  border-top-style: solid;
  border-top-color: #D18F42;
  background: #F9F9F9;
  border-radius: 0 0 0.5rem 0.5rem;
  font-family: 'DM Sans';
  font-style: normal;

  .title {
    width: 19.375rem;
    font-weight: 500;
    font-size: 1.25rem;
    line-height: 1.75rem;
    color: #323232;
    padding: 2rem 0 1rem 3rem;
  }

  ul {
    padding: 0 6rem 2.5rem 4.375rem;
    list-style: none;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.25rem;
    color: #323232;
  }

  ul li::before {
    content: "\2022";
    color: #D18F42;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
    font-size: 1.5rem;
  }
}

.roku-text-block {
  font-family: 'DM Sans';
  font-style: normal;
  color: #323232;

  .title {
    position: absolute;
    top: 6.25rem;
    font-weight: 500;
    font-size: 1.75rem;
    line-height: 2.25rem;
  }

  .description {
    position: absolute;
    top: 8.75rem;
    font-weight: 400;
    font-size: 1.125rem;
    line-height: 1.625rem;
  }
}

.faq-block {
  position: relative;
  background: #F9F9F9;
  border-radius: 0.25rem;
  height: 14rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-family: 'DM Sans';
  font-style: normal;

  hr {
    position: absolute;
    margin-top: 0;
    width: 1.188rem;
    height: 0.125rem;
    left: 2.5rem;
    top: 1.563rem;
    background: #D18F42;
  }

  .title {
    position: absolute;
    left: 2.5rem;
    top: 2.5rem;
    font-weight: 500;
    font-size: 1.5rem;
    line-height: 2rem;
    color: #323232;
  }

  .description {
    position: absolute;
    left: 2.5rem;
    top: 5rem;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.25rem;
    color: #323232;
    padding-right: 3.5rem;
  }
}

.faq-block-mobile-sm {
  @extend .faq-block;
  @include media-breakpoint-down(xs) {
    height: 12rem;
  }
}

.faq-block-mobile-md {
  @extend .faq-block;
  @include media-breakpoint-down(xs) {
    height: 16.5rem;
  }
}

.note-block {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.25rem;
  color: #323232;
  height: 8.75rem;
  padding: 1.5rem 3rem 1.5rem 3rem;
}

.note-block-active {
  background: #F3F4F5;
  border-radius: 0.25rem;
}

.title-links {
  text-align: center;
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 1.75rem;
  line-height: 2.25rem;
  color: #323232;

  hr {
    width: 2.563rem;
    height: 0.25rem;
    left: 2.5rem;
    top: 1.563rem;
    background: #D18F42;
  }
}

.card-link-block {
  background: #FFFFFF;
  box-shadow: 0 0.125rem 0.625rem rgba(73, 73, 73, 0.1);
  border-radius: 0.25rem;

  img {
    width: 100%;
    height: 19.75rem;
    background-size: contain;
    background-position: 100%;
    background-repeat: no-repeat;
  }

  .title {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 1.25rem;
    line-height: 1.75rem;
    color: #323232;
    padding: 1rem;
  }

  &:hover {
    box-shadow: 0 0.25rem 1.875rem rgba(98, 98, 101, 0.4);
  }

  @include media-breakpoint-down(xs) {
    img {
      width: 100%;
      height: 15.75rem;
      background-size: contain;
      background-position: 100%;
      background-repeat: no-repeat;
    }
  }
}

a {
  color: #2d8ac7;
}

.roku-app-carousel {
  display: block;
}

.mobile-carousel-block {
  position: relative;
  width: 100%;
  height: 4.188rem;
  background: #FFFFFF;
  box-shadow: 0 0.125rem 0.625rem rgba(73, 73, 73, 0.1);
  border-radius: 0.5rem;
  margin-right: 1.5rem;
  z-index: 100;
}

.mobile-carousel-image {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  margin-top: 1rem;
  filter: drop-shadow(0px 1.147rem 2.762rem rgba(0, 0, 0, 0.237222))
  drop-shadow(0px 0.613rem 2.011rem rgba(0, 0, 0, 0.196715))
  drop-shadow(0px 0.344rem 1.127rem rgba(0, 0, 0, 0.165))
  drop-shadow(0px 0.183rem 0.599rem rgba(0, 0, 0, 0.133285))
  drop-shadow(0px 0.076rem 0.249rem rgba(0, 0, 0, 0.092778));
}

.mobile-carousel-image img {
  flex-shrink: 0;
  object-fit: fill;
  width: 100%;
}

.mobile-carousel-header {
  position: absolute;
  top: 12%;
  width: 1rem;
  height: 2.2rem;
  margin: 0.438rem 1rem;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100% auto;
}
.mobile-header-icon-1 {
  background-image: url(#{$path-to-vue-apps}/roku/assets/images/Numbernum-1.png);
}

.mobile-header-icon-2 {
  background-image: url(#{$path-to-vue-apps}/roku/assets/images/Numbernum-2.png);
}

.mobile-header-icon-3 {
  background-image: url(#{$path-to-vue-apps}/roku/assets/images/Numbernum-3.png);
}

.mobile-header-icon-4 {
  background-image: url(#{$path-to-vue-apps}/roku/assets/images/Numbernum-4.png);
}

.mobile-carousel-text {
  margin: 0;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  left: 3rem;
  padding-right: 4rem;
  width: 100%;
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.25rem;
  color: #323232;
}

.mobile-carousel-lines {
  padding-top: 0.75rem;

  @include media-breakpoint-up(md) {
    padding-top: 0;
  }
}

.mobile-carousel-title {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 1.625rem;
  color: #323232;
  text-align: center;
  margin-bottom: 2.5rem;
}

.mobile-carousel-footer {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.25rem;
  align-items: center;
  color: #323232;
  margin-bottom: 2rem;
  padding-right: 4rem;
}
