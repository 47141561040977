body.topic {
  #ebook-preview-app {
    display: block;
  }

  .container {
    max-width: 1240px;
  }

  .navbar {
    box-shadow: 0 5px 16px rgba(157, 156, 170, 0.12);
  }

  .topic-wrapper {
    max-width: 1240px;
    margin: auto;
    margin-bottom: 96px;
  }
  .topic-banner {
    color: #FFF;
    margin-bottom: 40px;

    background: lightblue url(#{$path-to-img}/topics/cover.svg) no-repeat center;
    background-size: cover;

    h1.heading {
      color: #FFF;
      margin-bottom: 10px;
      margin-top: 24px;
      font-size: 36px;
      line-height: 44px;
      font-weight: 500;
    }
    .sub-heading {
      font-size: 24px;
      margin-bottom: 10px;
    }
    .description {
      font-size: 16px;
    }
    .text-col {
      margin: auto;
      text-align: center;
      padding-bottom: 56px;
    }
    .breadcrumbs-main .breadcrumb a.active  {
      color: #FFF;
    }
  }
  .ebooks-topic__banner {
    background: lightblue url(#{$path-to-img}/topics/ebooks_cover.svg) no-repeat center;
    background-size: cover;
  }

  .suggested_news {
    width: 100%;
    padding: 0 16px;

    @include media-breakpoint-down(md) {
      padding: 0;
    }
  }

  .news-row {
    display: flex;
    gap: 15px;
    width: 100%;
  }

  .news-row__news {
    margin-bottom: 32px;
    width: 235px;
    display: flex;
    flex-direction: column;
    position: relative;

    @include media-breakpoint-between(lg, xl) {
      width: 19%;
    }

    & > a {
      display: block;
    }
    p {
      margin-bottom: 8px;
    }

    .topics {
      max-height: 60px;
      overflow: hidden;
      justify-self: flex-end;
    }
    .topic {
      display: inline-block;
      max-width: 229px;
      padding: 4px 10px;
      height: 24px;
      background: #fff;
      border: 1px solid #d1d3d4;
      border-radius: 12px;
      font-size: 12px;
      line-height: 16px;
      color: #5c5c5c;
      cursor: pointer;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .suggested-entity-sub-link {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-family: DM Sans;
      font-size: 11px;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: 0.16em;
      color: #bb711a;
      text-transform: uppercase;
      margin-bottom: 10px;
    }
    .suggested-news-image {
      width: 100%;
      height: 168px;
      margin-bottom: 8px;
      border-radius: 4px;
      object-fit: cover;
    }
    .image-filler {
      height: 168px;
      margin-bottom: 8px;
    }
    .suggested-news-title {
      font-size: 16px;
      line-height: 20px;
      color: #323232;
      font-weight: 500;
      height: 60px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      line-clamp: 3;
      -webkit-box-orient: vertical;
    }
    .suggested-news-title__short {
      height: 40px;
      -webkit-line-clamp: 2;
      line-clamp: 2;
    }
    .suggested-entity-description {
      font-family: DM Sans;
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      color: #5c5c5c;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      line-clamp: 3;
      -webkit-box-orient: vertical;
      height: unset;
    }
    .suggested-entity-description__long {
      -webkit-line-clamp: 4;
      line-clamp: 4;
    }
    .publish-info {
      font-family: DM Sans;
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      color: #a6a6a6;
      display: flex;
      gap: 5px;
      margin-bottom: 10px;
    }
  }

  .news__new {
    position: absolute;
    top: 8px;
    left: 0;
    background: #D18F42;
    color: #FFF;
    border-radius: 0 4px 4px 0;
    padding: 4px 12px;
    font-size: 12px;

    @include media-breakpoint-down(md) {
      top: 23px;
      left: 15px;
    }
  }
  .news__new.news__new__t40 {
    top: 41px;
  }

  .ebooks-row__ebook .suggested-ebook-image {
    width: 200px;
    height: 294px;
    background-position: center;
    background-size: cover;
  }
  .ebooks-row__topic .suggested-ebook-topic-image {
    width: 170px;
    height: 234px;
    background-position: center;
    background-size: cover;
  }

  .ebooks-row__ebook .suggested-ebooks-title {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: normal !important;
    min-height: 40px;
  }

  .ebooks-row__ebook .suggested-ebooks-title,
  .ebooks-row__ebook .suggested-ebooks-description,
  .ebooks-row__ebook .suggested-ebooks-recommended,
  .ebooks-row__topic .suggested-ebook-topic-title,
  .ebooks-row__topic .suggested-ebook-topic-image {
    font-family: DM Sans, serif;
    min-height: 40px !important;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0;
    color: #323232;
    max-width: 200px;
    margin: 0;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
  }
  .ebooks-row__topic .suggested-ebook-topic-title,
  .ebooks-row__topic .suggested-ebook-topic-title {
    max-width: 170px;
  }
  .ebooks-row__ebook .suggested-ebooks-title {
    margin-top: 7px;
    font-size: 16px;
  }
  .ebooks-row__topic .suggested-ebook-topic-title {
    margin-top: 10px;
    font-size: 16px;
  }
  .ebooks-row__ebook .suggested-ebooks-description,
  .ebooks-row__ebook .suggested-ebooks-recommended {
    font-size: 12px;
  }
  .ebooks-row__ebook .suggested-ebooks-description {
    height: 20px;
    line-height: 16px;
  }
  .ebooks-row__ebook .suggested-ebooks-recommended {
    font-weight: 400;
    &.approved {
      color: #649546;
    }
  }
}

.suggested_ebooks,
.suggested_ebook-topics {
  display: flex;
  gap: 6px;
  width: 100%;
  flex-wrap: wrap;
}
.suggested_ebooks {
  gap: 0;
}

.suggested_ebook-topics {
  gap: 30px;
}

.ebooks-row__ebook,
.ebooks-row__topic {
  margin-bottom: 15px;
  padding: 15px;
  position: relative;
  border-radius: 10px;
  transition: all 0.3s;
  bottom: 0;
  &:hover {
    box-shadow: 0 2px 15px 0 rgba(29, 32, 35, 0.15);
    bottom: 5px;
  }
}
.ebooks-row__topic {
  box-shadow: 0 2px 15px 0 rgba(29, 32, 35, 0.15);
  &:hover {
    box-shadow: 0 2px 25px 0 rgba(29, 32, 35, 0.35);
    bottom: 0;
  }
}

@media (max-width: 1195px) {
  .suggested_ebooks,
  .suggested_ebook-topics {
    margin: auto;
  }
  .suggested_ebooks {
    width: 925px;
  }
  .suggested_ebook-topics {
    width: 890px;
  }
}
@media (max-width: 960px) {
  .suggested_ebooks {
    width: 695px;
  }
  .suggested_ebook-topics {
    width: 665px;
  }
}
@media (max-width: 720px) {
  .suggested_ebooks {
    width: 465px;
  }
  .suggested_ebook-topics {
    width: 430px;
  }
}
@media (max-width: 480px) {
  .suggested_ebooks {
    width: 235px;
  }
  .topic {
    .suggested_ebook-topics {
      width: 370px;
      gap: 10px;
      .ebooks-row__topic .suggested-ebook-topic-image {
        width: 148px;
        height: 185px;
        max-width: 148px;
      }
      .ebooks-row__topic .suggested-ebook-topic-title {
        max-width: 148px;
      }
    }
  }
}
@media (max-width: 370px) {
  .topic {
    .suggested_ebook-topics {
      width: 180px;
    }
  }
}

@media (max-width: 767px) {
  body.topic {
    .topic-banner {
      .sub-heading {
        margin-top: 0;
      }
      .text-col {
        padding-left: 15px;
        padding-right: 15px;
        text-align: left;
      }
    }
  }
}

@media (max-width: 992px) {
  body.topic {

    .topic-wrapper {
      .news-row  {
        flex-direction: column;
        gap: unset;
      }
      .news-row__news {
        padding: 15px;
        width: 100%;
        margin-bottom: 28px;

        .suggested-news-image {
          height: auto;
        }
        .suggested-news-title {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          line-clamp: 3;
          -webkit-box-orient: vertical;
          height: unset;
        }
      }
    }
  }
}

#store-items-app {
  display: block;
}

#all-store-items-app {
  display: block;
}
