.prophecy-news {
  .source-header {
    color: #5C5C5C;
    font-size: 14px;
    line-height: 20px;
  }

  .source-content {
    display: flex;
    align-items: center;
    color: #5C5C5C;
    font-size: 12px;
    line-height: 15px;
    margin-bottom: 24px;
    margin-top: 8px;
    gap: 8px;
  }

  .source-text {
    font-size: 14px;
    line-height: 20px;
    display: flex;
    flex-direction: column;
  }

  .source-text__black {
    color: #323232;
  }

  .container {
    max-width: 1240px;
  }

  .navbar {
    box-shadow: 0 5px 16px rgba(157, 156, 170, 0.12);
  }

  .copy_current_url-tooltip__container {
    display: none;
    position: relative;
    span {
      position: absolute;
      top: -36px;
      left: -30px;
      font-family: 'DM Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      padding: 6px 12px;
      background: #FFFFFF;
      box-shadow: 0 2px 15px rgba(73, 73, 73, 0.2);
      border-radius: 4px;
      color: #323232 !important;
    }
  }

  .prophecy-news-wrapper {
    max-width: 1240px;
    display: flex;
    margin: auto;
    margin-bottom: 96px;

    p {
      margin-bottom: 16px;
    }

    .topic {
      display: inline-block;
      max-width: 229px;
      padding: 4px 10px;
      height: 24px;
      background: #fff;
      border: 1px solid #d1d3d4;
      border-radius: 12px;
      font-size: 12px;
      line-height: 16px;
      color: #5c5c5c;
      cursor: pointer;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .news-content-block {
      padding: 0 16px;
      padding-right: 60px;
      width: calc(100% - 340px);
      color: #323232;

      .news-source-logo {
        height: 35px;
        margin: 8px 8px 8px 0;
        width: auto;
      }

      h1.heading {
        font-size: 40px;
        font-weight: 500;
        margin-bottom: 8px;
      }

      .topics {
        margin-bottom: 16px;
      }

      .news-description {
        font-size: 16px;
        margin-bottom: 24px;
        line-height: 20px;
      }

      img.news-image {
        width: 100%;
        border-radius: 4px;
      }

      div.news-content-body {
        font-size: 16px;
      }

      .author-container {
        padding: 16px 0;
        display: flex;
        justify-content: space-between;

        .news-author-block {
          display: flex;

          img.news-author-photo {
            width: 24px;
            height: 24px;
            border-radius: 50%;
            align-self: center;
            margin-right: 8px;
          }
          .news-author-name {
            font-size: 14px;
            color: #5C5C5C;
          }
          .news-date {
            font-size: 12px;
            color: #5C5C5C;
            margin-top: -3px;
          }
        }
        .news-share {
          display: flex;
          width: 200px;
          justify-content: space-between;

          a {
            color: #A6A6A6;
            svg {
              height: 18px;
            }
            svg.feather-facebook, svg.feather-twitter, svg.feather-linkedin {
              fill: #A6A6A6;
            }
            cursor: pointer;
          }
          a:hover {
            color: #5C5C5C;
            svg.feather-facebook, svg.feather-twitter, svg.feather-linkedin {
              fill: #5C5C5C;
            }
          }
        }
      }

      .scroll-top-button-container {
        position: relative;

        .scroll-top-button {
          right: -64px;
          bottom: 115px;
          color: #6998AE;
          background: white;
          display: block;
          position: absolute;
          border-radius: 50%;
          box-shadow: 0 2px 10px rgb(73 73 73 / 15%);
          padding: 10px;
          line-height: 0;
          cursor: pointer;
          svg {
            height: 24px;
            width: 24px;
          }
        }
      }
      .scroll-top-button-container.fixed {
        float: right;

        .scroll-top-button {
          position: fixed;
          right: unset;
          margin-left: 10px;
        }
      }
    }
    .image-with-full-view {
      width: 100%;
      height: 360px;
      overflow: hidden;
      margin-top: 30px;
      cursor: pointer;
      position: relative;
      background: rgba(28, 25, 35, 0.6);
      img {
        opacity: 0.2;
        width: 100%;
      }
      .hint {
        position: absolute;
        bottom: 24px;
        font-size: 18px;
        padding: 18px 70px;
        color: #6998AE;
        background: #FFFFFF;
        border-radius: 8px 0;
        left: 50%;
        transform: translateX(-50%);
      }
    }

    .right-block {
      width: 340px;
      flex-shrink: 0;
      display: flex;
      flex-direction: column;
      border-left: 2px solid #EAEDEE;
    }
  }
}

.news-suggested-block {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 24px;

  .news-orange-block {
    align-self: start;
  }

  .news-suggested__header {
    font-weight: 500;
    padding-bottom: 6px;
  }

  .suggested-news {
    margin-top: 3px;
    display: flex;
    gap: 16px;
    flex-wrap: wrap;
    overflow: hidden;
    height: 287px;
    max-height: 287px;
    width: 100%;

    p {
      color: #323232;
      font-size: 20px;
    }

    .news {
      width: 264px;

      & > a {
        display: block;
      }
      p {
        margin-bottom: 8px;

      }

      .topics {
        max-height: 60px;
        overflow: hidden;
      }

      .suggested-news-image {
        width: 100%;
        height: 139px;
        min-width: 248px;
        margin-bottom: 8px;
        border-radius: 4px;
      }
      .suggested-news-title {
        font-size: 16px;
        line-height: 20px;
        color: #323232;
        font-weight: 500;
        height: 60px;
        overflow: hidden;
      }
    }
  }
}

@media (max-width: 992px) {
  .scroll-top-button-container { display: none }

  .prophecy-news {
    .prophecy-news-wrapper {
      flex-direction: column;

      .news-content-block {
        padding-right: 16px;
        width: 100%;

        .author-container {
          flex-direction: column;
          height: 115px;
        }
      }
      .image-with-full-view {
        margin-bottom: 30px;
        height: auto;

        .hint {
          border-radius: 50%;
          padding: 5px;
          color: #414042;
          background: #6998AE;
          line-height: 5px;
          left: unset;
          right: 0;

          span {
            display: none;
          }
        }
      }


      .right-block {
        width: 100%;
        flex-direction: column-reverse;
      }
    }
  }
}

.news-bookmarks-app,
.flash-notifications-app {
  display: flex;
}

.news-content-app {
  display: block;
  width: 100%;
}

.toggle-news-bookmarks-container {
  position: relative;

  .toggle-news-bookmarks {
    right: -77px;
    top: 0;
    color: #ffffff;
    background: #6998AE;
    display: block;
    position: absolute;
    border-radius: 50%;
    box-shadow: 0 2px 10px rgb(73 73 73 / 15%);
    padding: 8px;
    line-height: 0;
    cursor: pointer;
  }

  .toggle-news-icon {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'%3E%3Cpath d='M6 12L10 8L6 4' stroke='white' stroke-width='1.33333' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
    width: 16px;
    height: 16px;
  }

  &.closed {
    .toggle-news-bookmarks {
      right: -80px;
      top: -3px;
    }

    .toggle-news-icon {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cpath d='M19 21L12 16L5 21V5C5 4.46957 5.21071 3.96086 5.58579 3.58579C5.96086 3.21071 6.46957 3 7 3H17C17.5304 3 18.0391 3.21071 18.4142 3.58579C18.7893 3.96086 19 4.46957 19 5V21Z' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
      width: 24px;
      height: 24px;
    }
  }
}

#news-featured-videos-app {
  display: block;
}
