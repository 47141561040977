.flag {
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
  position: relative;
  display: inline-block;
  line-height: 1em;
  width: 1.25rem;
  height: 1.25rem;
  vertical-align: -0.188rem;
  padding-right: 1.6rem;
}

.flag-round-ca {
  background-image: url(#{$path-to-img}/flags/ca.png);
}

.flag-round-us {
  background-image: url(#{$path-to-img}/flags/us.png);
}
