// NOTE: don't wrap general navbar styles into .site-main: it breaks styles because .navbar rules
// are defined in Bootstrap CSS files. Only wrap CSS customizations with .site-main.
.navbar {
  @extend .navbar-expand-lg;
  @extend .fixed-top;
  @extend .d-block;
  @extend .p-0;

  // Fix for the last dropdown menu section jumping off-screen when opening the BS collapse
  // https://github.com/twbs/bootstrap/issues/23374#issuecomment-324747875
  max-height: 100%;

  @include media-breakpoint-up(lg) {
    overflow-y: visible;
  }

  font-weight: $font-weight-bold;
  transition: background-color 0.3s ease-in;

  %navbar-mobile-row-padding {
    padding-left: 36px;
    padding-right: 16px;
  }

  .navbar-logo-container {
    @extend %navbar-mobile-row-padding;
    @extend .d-flex;
    @extend .w-100;
    @extend .w-lg-auto;
    @extend .px-lg-0;
  }

  .nav-link, .profile-logo-title {
    transition: color 0.3s ease-in;
  }

  .toggler-icon {
    transition: stroke 0.3s ease-in;
  }

  .profile-logo-header .fill {
    transition: fill 0.3s ease-in;
  }

  .login-welcome-tooltip__container {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.3s linear;
    display: flex;
    justify-content: center;
    position: absolute;
    padding: 16px 16px;
    top: 80px;
    right: 54px;
    background: #B56941;
    border-radius: 8px;
    box-shadow: 0 4px 30px rgba(98, 98, 101, 0.4);
    align-items: center;
    gap: 50px;
    color: white;
    stroke: white;
    .login-tooltip__title {
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: #FFFFFF;
    }

    .login-tooltip__close {
      cursor: pointer;

      .fill {
        fill: #FFFFFF;
      }
    }
  }
  .login-tooltip__container, .login-welcome-tooltip__container {
    display: none;
  }

  &::before {
    @extend .d-none;
  }

  div.mobile, div.desktop {
    display: flex;

    .profile-logo-header {
      align-self: center;
      display: flex;
    }
  }

  div.desktop {
    @include media-breakpoint-down(md) {
      display: none;
    }
  }

  &.navbar-dark {
    background-color: transparent;
    box-shadow: none;

    .logo {
      @include media-breakpoint-down(md) {
        left: -1.5rem;
      }
    }

    .btn-donate {
      @extend .btn-light;
      @extend .mr-lg-4;
    }

    .toggler-icon {
      stroke: $white;

      path {
        fill: $white;
      }
    }

    .profile-logo-header {
      .fill {
        fill: $white;
      }
    }

    .search-btn {
      path {
        fill: #FFFFFF;
      }
    }

    .profile-home .profile-logo-title {
      color: $white;
    }

    .profile-logo-title {
      color: $black;
      margin-left: 0.5rem;
      font-weight: 500;
      font-size: 16px;
      line-height: 36px;

      &:hover {
        color: $white;
      }
    }
    // TODO: move usages of this mixin to the respective elements' stylesheet files
    @mixin black-navbar-content {
      .toggler-icon {
        stroke: $black;

        path {
          fill: $black;
        }
      }

      .logo {
        fill: $black;
      }

      .nav-link {
        color: $black;
      }

      .profile-logo-header {
        .fill {
          fill: $black;
        }
      }
    }

    &.navbar-fill-bg {
      @include black-navbar-content;

      .search-btn {
        path {
          fill: #323232;
        }
      }
    }

    &.navbar-hero-2-col {
      @include media-breakpoint-up(md) {
        @include black-navbar-content;
      }

      .search-btn {
        path {
          fill: #323232;
        }
      }
    }

    &.navbar-speaker-bio-summary {
      @include black-navbar-content;
    }
  }

  &.navbar-light {
    background-color: white;

    .main-menu-wrapper {
      .nav-link.active {
        color: $primary;
      }
    }

    .logo,
    .unauthorized-profile-logo path {
      fill: $black;

      @include media-breakpoint-down(md) {
        left: -1.5rem;
      }
    }

    .toggler-icon {
      stroke: $black;

      path {
        fill: $black;
      }
    }

    .profile-logo-header {
      .fill {
        fill: $black;
      }
    }

    .profile-logo-title {
      color: $black;
      margin-left: 0.5rem;
      font-weight: 500;
      font-size: 16px;
      line-height: 36px;

      &:hover {
        color: $black;
      }
    }
  }

  .dropdown-header {
    @extend .font-size-xs;

    color: $subheadings-secondary;
  }

  .desktop-more-nav-item {
    cursor: pointer;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;

    @include media-breakpoint-down(lg) {
      display: none;
    }
  }

  .nav-link-position {
    position: relative;
  }

  .navbar-dropdown-menu {
    margin-right: 0 !important;
    min-width: 37rem !important;
    background-color: #ffffff;
    right: 0;
    border-radius: 0 0 1rem 1rem;
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
    box-shadow: none;
  }

  .more-navbar-block {
    max-width: 11.5rem;
    min-width: 9.5rem;

    @include media-breakpoint-down(lg) {
      display: none;
    }
  }

  .navbar-sections-row.more {
    justify-content: space-between;
  }

  .mobile-navbar-nav {
    @include media-breakpoint-up(sm) {
      display: none;
    }
  }

  .more-navbar {
    font-family: 'DM Sans', serif;
    font-style: normal;
    padding-top: -0.5rem;
    text-align: left;
    list-style-type: none;
  }

  .more-header-link-block {
    margin-left: 0 !important;
    padding-left: 0;
    padding-bottom: 1rem;
  }

  .more-header-link {
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.125rem;
    color: #323232 !important;

    &:hover {
      color: #6998ae !important;
    }
  }

  .more-header {
    font-weight: 500;
    font-size: 14px;
    line-height: 1.25rem;
    color: #5C5C5C;
    padding-bottom: 1rem;
    text-transform: uppercase;
    font-family: 'DM Sans', serif;
  }

  .dropdown-header-container {
    .dropdown-toggle-icon {
      color: #A6A6A6 !important;
    }
  }

  .nav-item-mobile-title {
    font-family: 'DM Sans', serif;
    font-style: normal;
    font-weight: 500;
    color: #323232;
    font-size: 14px !important;
    line-height: 1.625rem !important;
  }

  .nav-item-mobile-link {
    font-family: 'DM Sans', serif;
    font-style: normal;
    font-weight: 500;
    color: #FFFFFF;
    font-size: 0.875rem !important;
    line-height: 1.125rem !important;
  }

  .navbar-home-link {
    @extend .navbar-brand;

    @include media-breakpoint-down(xs) {
      width: 120px;
    }

    svg {
      @extend .position-relative;

      top: -5px;
      fill: $white;
    }
  }

  .btn-donate {
    @extend .btn;
    @extend .btn-primary;
    @extend .d-lg-block;
    @extend .d-none;
    @extend .mr-lg-4;
  }

  .navbar-collapse .nav-link {
    @extend %navbar-mobile-row-padding;

    padding: 1.125rem 16px !important;
  }

  @include media-breakpoint-down(sm) {
    padding-top: 0;
    padding-bottom: 0;
    line-height: 1.6;

    .navbar-home-link svg {
      height: 30px;
    }
  }

  @include media-breakpoint-up(lg) {
    font-size: $font-size-lg;
  }

  @media (min-width: 1600px) {
    .navbar-container {
      max-width: 70%;
    }
  }

  @media (max-width: 992px) {
    .profile-logo-header {
      display: none;
    }
  }
}
