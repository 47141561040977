@import 'application/navbar_mixins';

// Mobile styles

.watch-landing-sub-menu {
  // Index pages menu items styles

  .index-page-nav-link {
    @extend .d-lg-none;
  }

  // Dropdown menu styles

  .dropdown {
    @extend .d-none;
    @extend .d-lg-block;
    @extend .position-relative;
  }
}

.body:not(.site-watch) .watch-landing-sub-menu.embedded {
  @extend %main-menu-watch-sub-menu-mobile;
  @extend .d-lg-none;

  .nav-item:nth-child(n + 2) {
    @include navbar-item-border-top;
  }

  background-color: rgba($porcelain, 0.4);
}
