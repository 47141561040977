.donate-section {
  @extend .position-relative;
  @extend .pt-5;

  // TODO: DRY up and refactor into mixins / functions
  $header-height: 20.75rem; // 332px
  $card-border-top: 0.125rem; // 2px
  $angle-shape-height: 2.5rem; // 40px
  $donate-card-top-shift: $header-height + $card-border-top +
    $angle-shape-height;
  $footer-margin-bottom: $donate-card-top-shift;

  $header-height-sm: $header-height - 2rem;
  $donate-card-top-shift-sm: $header-height-sm + $card-border-top +
    $angle-shape-height;
  $footer-margin-bottom-sm: $donate-card-top-shift-sm - 2rem;

  $angle-shape-height-md: 6rem;
  $header-height-md: $header-height - 4rem;
  $donate-card-top-shift-md: $header-height-md + $card-border-top +
    $angle-shape-height-md;
  $footer-margin-bottom-md: $donate-card-top-shift-md - 6rem;

  $donate-card-top-shift-lg: $donate-card-top-shift-md;

  background-color: $porcelain;

  .donate-header {
    padding-top: $header-height;

    @include media-breakpoint-only(sm) {
      padding-top: $header-height-sm;
    }

    @include media-breakpoint-only(md) {
      padding-top: $header-height-md;
    }
  }

  .shape-container {
    height: 2.5rem; // 40px

    @include media-breakpoint-up(md) {
      height: 6rem; // 96px
    }
  }

  .shape-img {
    @extend .pt-7; // 40px
    @extend .pt-md-11; // 96px
    @extend .pb-0;

    color: $white;

    svg {
      // .shape-container is in the flow, can't apply `@extend .shape-bottom` to .shape
      transform-origin: bottom center;
    }
  }

  .donate-row {
    background-color: $white;

    @include media-breakpoint-up(lg) {
      height: 38.875rem - $donate-card-top-shift-lg; // 622px - header height
    }
  }

  $width-xxl: 2000px;

  .donate-card {
    $donate-card-px: 3rem; // 40px
    $donate-card-pb: $donate-card-px * 2 + 3.375rem; // 2 default bottom paddings + button height

    top: -$donate-card-top-shift;
    z-index: 1020;
    min-height: 32.5rem; // 520px
    padding: 2.25rem $donate-card-px 11rem $donate-card-px;
    border-top: 0.125rem solid $raw-sienna;

    @include media-breakpoint-only(sm) {
      top: -$donate-card-top-shift-sm;
    }

    @include media-breakpoint-only(md) {
      top: -$donate-card-top-shift-md;
      width: 75%;
      margin: 0 auto;
    }

    @include media-breakpoint-up(lg) {
      position: absolute !important;
      top: 4.75rem; // 76px
      width: 32.125rem; // 514px
      left: 50%;
      transform: translateX(-75%);
    }

    @include media-breakpoint-only(lg) {
      position: absolute !important;
      top: 4.75rem; // 76px
      width: 60%;
      left: 65%;
      transform: translateX(-75%);
    }

    @include media-breakpoint-up(xl) {
      transform: translateX(-100%);
    }

    @media (min-width: $width-xxl) {
      transform: translateX(-120%);
    }

    .donate-store {
      @extend .position-absolute;
      display: grid;
      grid-template-areas: "line1 line1"
                           "line2 line2"
                           "line3 line3";
      margin-left: auto;
      margin-right: auto;
      left: 0;
      right: 0;
      text-align: center;

      width: fit-content;
      bottom: $donate-card-px;

      .title {
        grid-area: line1;
        font-style: normal;
        font-weight: 500;
        font-size: 1.75rem;
        line-height: 2.25rem;
        align-items: center;
        text-align: center;
        padding-bottom: 0.625rem;
        color: #323232;
      }

      .line2 {
        grid-area: line2;
      }

      .line3 {
        grid-area: line3;
      }

      .group {
        width: 20rem;

        @include media-breakpoint-down(sm) {
          width: 100%;
        }
      }

      .btn-donate-giving {
        display: block;
        position: absolute;
        right: -30rem;
        width: 20rem;
        bottom: 0rem;

        @include media-breakpoint-down(lg) {
          display: block;
          position: relative;
          margin-top: 0.5rem;
          right: 0;
          bottom: 0;
        }
      }
    }
  }

  .heading {
    @extend .text-center;
  }

  .cover-wrapper {
    @extend .d-none;
    @extend .d-lg-block;
    @extend .position-absolute;

    top: 1rem;
    right: 50%;
    z-index: 1010;
    transform: translate(70%, -10%);

    @include media-breakpoint-down(lg) {
      display: none !important;
    }

    @include media-breakpoint-up(xl) {
      transform: translate(88%, 0);
    }

    @media (min-width: $width-xxl) {
      transform: translate(67%, 0);
    }
  }

  .cover {
    @include object-fit-cover;

    width: 31.813rem;
    height: 26rem;
  }

  .donate-footer {
    margin-top: -$footer-margin-bottom;

    @include media-breakpoint-only(sm) {
      margin-top: -$footer-margin-bottom-sm;
    }

    @include media-breakpoint-only(md) {
      margin-top: -$footer-margin-bottom-md;
    }

    @include media-breakpoint-up(lg) {
      margin-top: 0;
    }
  }

  .btn {
    @extend .lift;
  }
}
